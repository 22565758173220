import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from 'react';
import { Button, Input, Popconfirm, Space, Table, Tag } from 'antd';
import type { ColumnsType, } from 'antd/es/table';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ItemApi, ItemDto, Configuration, ItemCategoryDto, PartyDto } from "../../api/najd";
import { getFullDateTime } from "../../helper";
import { API_URL } from "../../constants";

interface DataGridProps {
    partyInfo: PartyDto;
    onClickRowEdit: (rowData: any) => void;
    refreshTrigger: boolean;
    setRefreshTrigger: React.Dispatch<React.SetStateAction<boolean>>;
}

const ItemsTable: React.FC<DataGridProps> = ({ onClickRowEdit, refreshTrigger, setRefreshTrigger, partyInfo }) => {
    const { t } = useTranslation();
    const [userData, setUserData] = useState<ItemDto[]>([]);
    const [loading, setLoading] = useState(false);

    const [searchText, setSearchText] = useState('');
    const party_id = partyInfo.allowCreatePartyItem == true ? partyInfo.id : undefined;

    const configuration = new Configuration({
        basePath: API_URL,
    });
    const itemApi = new ItemApi(configuration);

    const columns: ColumnsType<ItemDto> = [
        { title: t('Serial'), dataIndex: 'serial', key: 'serial', },
        { title: t('Code'), dataIndex: 'code', key: 'code', },
        {
            title: t('Name'), dataIndex: 'name', key: 'name',
            filteredValue: [searchText],
            onFilter: (value, record) => {
                return (
                    String(record.name)
                        .toLowerCase()
                        .includes(value.toString().toLowerCase()) ||
                    String(record.shortDescription)
                        .toLowerCase()
                        .includes(value.toString().toLowerCase())
                );
            },
        },
        {
            title: t('Short Description'), dataIndex: 'shortDescription', key: 'shortDescription'
        },
        {
            title: t('Unit Price'), dataIndex: 'unitPrice', key: 'unitPrice',
        },
        // {
        //     title: t('Categories'),
        //     dataIndex: 'itemCategories',
        //     key: 'itemCategories',
        //     width: 150,
        //     render: (_, record) => {
        //         return <span>{record.itemCategories !== null ? record.itemCategories?.map(s => s.category_Id).join(', ') : ''}</span>;
        //     },
        // },
        {
            title: t('Creation Time'), dataIndex: 'creationTime', key: 'creationTime', render: ((date: string) => getFullDateTime(date))
        },
        {
            title: t('Active'),
            dataIndex: 'active',
            key: 'active',
            render: (active: boolean) => <span>{active ? 'Yes' : 'No'}</span>,
        },
        // {
        //     title: t('Active'),
        //     dataIndex: 'active',
        //     key: 'active',
        //     render: (active: boolean) => <span>{active ? 'Yes' : 'No'}</span>,
        // },
        {
            title: t('Action'),
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button type="primary" icon={<EditOutlined />} title={t('Edit') ?? ''} onClick={() => { handleRowClick(record) }} />
                    <Popconfirm
                        title={t("Are you sure you want to delete this item?")}
                        onConfirm={() => handleDelete(record.id)}
                        okText={t("Yes")}
                        cancelText={t("No")}
                    >
                        <Button type="default" title={t('Delete') ?? ''} icon={<DeleteOutlined />} danger
                            key={'con-btn-' + record.id} />
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    // Use the onClickRowEdit function as needed
    const handleRowClick = (rowData: any) => {
        onClickRowEdit(rowData);
    };

    const handleDelete = (id: any) => {
        console.log('Clicked cancel button');
        itemApi.itemDelete(id)
            .then(response => {
                // Handle the API response            
                console.log(response.data);
                handleRefresh();
            })
            .catch(error => {
                // Handle the API error
                console.error(error);
            });
    };

    useEffect(() => {
        setLoading(true);
        const fetchUserData = async () => {
            try {
                const response = await itemApi.itemGetList(undefined,
                    undefined,
                    undefined,
                    undefined,
                    party_id??undefined);
                setUserData(response.data.items ?? []);
                setLoading(false);
            } catch (error) {
                console.error(error);
            }
        };
        fetchUserData();
    }, [refreshTrigger]); // Add refreshTrigger as a dependency

    const handleRefresh = () => {
        setRefreshTrigger((prevState) => !prevState); // Toggle the refresh trigger
    };

    return (<>
        {/* <button onClick={handleRefresh}>Refresh Data</button> */}
        <Input.Search
            placeholder={t('Search') ?? ''}
            onSearch={(value) => {
                setSearchText(value);
            }}
            onChange={(e) => {
                setSearchText(e.target.value);
            }}
        ></Input.Search>
        <Table<ItemDto>
            rowKey={(record) => record.id ?? ''}
            dataSource={userData}
            columns={columns}
            loading={loading}
        />
    </>
    );
};

export default ItemsTable;