import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Button, Modal, Steps, message } from "antd";
import { PageContainer, ProCard, } from '@ant-design/pro-components';
import 'antd/dist/reset.css';
import FormDrawer from "../../components/FormDrawer";
import { DocumentsForm } from "./DocumentsForm";
import { Configuration, DocumentApi, ItemApi, LocationApi, PartyApi, ConfigurationParameters, PartyDto } from "../../api/najd";
import DocumentsTable from "./DocumentsTable";
import { API_URL } from "../../constants";
import { DocumentsCreateForm } from "./DocumentsCreateForm";
import { useAuth } from "react-oidc-context";

interface DocumentsProps {
    partyInfo: PartyDto;
}
export const Documents: React.FC<DocumentsProps> = ({ partyInfo }) => {
    const auth = useAuth();
    const { t } = useTranslation();
    const { type } = useParams(); // Access the "type" parameter
    const [editMode, setEditMode] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState<any>(null);
    const [refreshTrigger, setRefreshTrigger] = useState<boolean>(false);
    const partnerParty_Id: string | null | undefined = partyInfo.code === 'patient' ? partyInfo.id : null;

    const [openEdit, setOpenEdit] = useState(false);


    const token = auth.user?.access_token;
    const configParameters: ConfigurationParameters = {
        basePath: API_URL,
        accessToken: token,
        baseOptions: {
            headers: { Authorization: 'Bearer ' + token },
        }
    };
    const configuration = new Configuration(configParameters);
    const itemApi = new ItemApi(configuration);

    const locationApi = new LocationApi(configuration);
    const partyApi = new PartyApi(configuration);
    const documentApi = new DocumentApi(configuration);

    const closeDrawer = () => {
        setDrawerOpen(false);
    };

    const handleRefresh = () => {
        setRefreshTrigger((prevState) => !prevState);
    };

    const onClickCreateNew = () => {
        // Handle create new action
        console.log('Click create new');
        setEditMode(false);
        setSelectedRowData({
            code: type,
            partnerParty_Id: partnerParty_Id
        }); // Set the selected row data in the state
        console.log('set null=>', selectedRowData);
        setDrawerOpen(true);
    };

    const onClickRowEdit = (rowData: any) => {
        // Handle the row edit action
        console.log('Click editing row:', rowData);
        setEditMode(true);
        setSelectedRowData(rowData); // Set the selected row data in the state
        // setDrawerOpen(true);
        setOpenEdit(true);
    };

    const onClickSubmitButton = () => {
        // Handle the row edit action
        console.log('=> onClickSubmitButton');
        if (selectedRowData) {
            handleFormSubmit(selectedRowData, false, false);
        }
    };
    // const onSubmit = (formData: any) => {
    //     // Handle the row edit action
    //     console.log('On Submit:', formData);
    // };

    // Form Events
    const handleFormSubmit = (formData: any, approve: boolean, reject: boolean) => {
        // Handle form submission in the parent component
        console.log('Form Data:', formData);
        if (editMode == true) {
            documentApi.documentUpdate(formData.id, formData)
                .then(response => {
                    // Handle the API response
                    console.log(response.data);
                    message.success(t('Submit Successfully'));
                    if (approve == true) {
                        documentApi.documentApprove(formData.id)
                            .then(response => {
                                handleRefresh();
                                setOpenEdit(false);
                            })
                            .catch(error => {
                                // Handle the API error
                                message.error(t('Failed to submit'));
                                console.error(error);
                            });
                    } else if (reject == true) {
                        documentApi.documentReject(formData.id)
                            .then(response => {
                                handleRefresh();
                                setOpenEdit(false);
                            })
                            .catch(error => {
                                // Handle the API error
                                message.error(t('Failed to submit'));
                                console.error(error);
                            });
                    } else {
                        handleRefresh();
                        setOpenEdit(false);
                    }
                })
                .catch(error => {
                    // Handle the API error
                    console.error(error);
                });
        } else {
            documentApi.documentCreate(formData)
                .then(response => {
                    // Handle the API response
                    console.log(response.data);
                    closeDrawer();
                    handleRefresh();
                    message.success(t('Submit Successfully'));
                })
                .catch(error => {
                    // Handle the API error
                    console.error(error);
                });
        }
    };


    const handleFormChange = (formData: any) => {
        console.log('handleFormChange:', formData);
        setSelectedRowData(formData);
    };
    // End Form Events
    return (
        <PageContainer
            header={{
                title: t('Requests'),
                ghost: true,
                breadcrumb: {
                    items: [
                        {
                            path: '/',
                            title: t("Home"),
                        },
                        {
                            path: '/documents/' + type ?? 'document',
                            title: t('Requests'),
                        },
                    ],
                },
                extra: [
                    <Button hidden={(partyInfo.code == 'admin' || partyInfo.code == 'patient' || partyInfo.code == 'social') ? false : true} key="3" type="primary" onClick={onClickCreateNew}>
                        {t("Add New")}
                    </Button>,
                ],
            }}
        >
            <ProCard>
                <DocumentsTable
                    type={type}
                    partyInfo={partyInfo}
                    onClickRowEdit={onClickRowEdit}
                    refreshTrigger={refreshTrigger}
                    setRefreshTrigger={setRefreshTrigger}
                />
                <FormDrawer open={drawerOpen}
                    onClose={closeDrawer}
                    formData={selectedRowData}
                    title={((editMode) ? t("Edit") : t("Add New"))}
                    onSubmit={onClickSubmitButton}>
                    <DocumentsCreateForm
                        type={type}
                        partyInfo={partyInfo}
                        onClose={closeDrawer}
                        formData={selectedRowData}
                        onSubmit={(resp) => {
                            handleFormSubmit(resp, false, false);
                        }}
                        onChange={handleFormChange} />
                </FormDrawer>
                <Modal
                    open={openEdit}
                    centered
                    width={Math.max(1200, window.innerWidth - 300)}
                    title={t('Request Details')}
                    // okText="حفظ"
                    // cancelText="Cancel"
                    okButtonProps={{ disabled: true }}
                    cancelButtonProps={{ disabled: true }}
                    footer={[

                    ]}
                    onCancel={() => {
                        setOpenEdit(false);
                    }}
                >
                    <DocumentsForm
                        type={type}
                        partyInfo={partyInfo}
                        onClose={() => {
                            setOpenEdit(false);
                            handleRefresh();
                            message.success(t('Submit Successfully'));
                        }}
                        formData={selectedRowData}
                        onSubmit={(resp) => {
                            handleFormSubmit(resp, false, false);
                        }}
                        onApprove={(resp) => {
                            handleFormSubmit(resp, true, false);
                        }}
                        onReject={(resp) => {
                            handleFormSubmit(resp, false, true);
                        }}
                        onChange={handleFormChange} />

                </Modal>
            </ProCard>
        </PageContainer>
    );
};
