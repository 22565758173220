import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";

// const getCurrentHost = 
//   import.meta.env.MODE === "development"
//     ? "http://localhost:4200"
//     : "LINK TO PROD";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // detection: options,
    fallbackLng: "en",
    // lng: "en",
    interpolation: {
      escapeValue: false,
    },
    backend: {
      // loadPath: `${getCurrentHost}/i18n/{{lng}}.json`,
      loadPath: `${window.location.origin}/i18n/{{lng}}.json`,
    },
  });

export default i18n;