export const LANGUAGES = [
  { label: "العربية", code: "ar" },
  { label: "English", code: "en" },
];

export const enalbleOtp = false;

// export const Auth_client_id="App_react"
// export const API_URL="https://localhost:44362"
// export const API_URL_Upload="https://localhost:44362/FileUpload/upload"
// export const Rresource_Path="https://localhost:44362/uploads/"
// export const Report_URL="https://localhost:7234"

export const Auth_client_id="App_react_prod"
export const API_URL="https://health-api.alaliwi.com"
export const API_URL_Upload="https://health-api.alaliwi.com/FileUpload/upload"
export const Rresource_Path="https://health-api.alaliwi.com/uploads/"
export const Report_URL="http://88.99.98.220:8082"

// export const Auth_client_id="App_react_wecare"
// export const API_URL="https://api.wecare360.org"
// export const API_URL_Upload="https://api.wecare360.org/FileUpload/upload"
// export const Rresource_Path="https://api.wecare360.org//uploads/"
// export const Report_URL="http://88.99.98.220:8082"

// interface MenuItem {
//   path: string;
//   name: string;
//   icon?: React.ReactNode;
//   children?: MenuItem[]; // Optional children property for nested menu items
// }


export const phoneRegExp = new RegExp(/^[\d]{0,10}$/);