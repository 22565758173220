import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, InputNumber, Row, Select, SelectProps, Space, Switch } from 'antd';
import { CategoryApi, Configuration, ConfigurationParameters, ItemDto } from "../../api/najd";
import { API_URL } from "../../constants";
import { useAuth } from "react-oidc-context";

interface FormDrawerProps {
  formData?: ItemDto; // Prop to receive the rowData
  editMode: boolean; // Prop to receive the rowData
  onClose: () => void;
  onSubmit: (formData: any) => void;
  onChange: (formData: any) => void;
}

export const ItemsForm: React.FC<FormDrawerProps> = ({ formData, onSubmit, onChange, onClose, editMode }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm(); // Create a form instance using the useForm hook

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(formData); // Set initial form values when rowData changes
    form.setFieldsValue({ unitPriceIncludeTax: (formData?.taxAmountPerUnit??0) + (formData?.unitPrice??0) });
  }, [form, formData]);

  const [options, setOptions] = useState<SelectProps['options']>([]);
  const auth = useAuth();

  const token = auth.user?.access_token;
  const configParameters: ConfigurationParameters = {
      basePath: API_URL,
      accessToken: token,
      baseOptions: {
          headers: { Authorization: 'Bearer ' + token },
      }
  };
  const configuration = new Configuration(configParameters);
  
  const categoryApi = new CategoryApi(configuration);
  /***start get options */
  useEffect(() => {
    // Fetch the select options from the API
    fetchOptions()
      .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.map(({ name, id }) => (
          {
            label: name,
            value: id
          }
        ));
        setOptions(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });
  }, []); // Run this effect only once when the component mounts

  const fetchOptions = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return categoryApi.categoryGetList('item')
      .then((response) => response.data)
      .then((data) => data.items);
  };
  /***end get options */

  // Function to handle form submission
  const handleSubmit = () => {
    form.validateFields().then(values => {
      onSubmit(values);
    });
  };

  const onValuesChange = (changedValues: any, allValues: any) => {
    console.log(changedValues);
    const value = Object.keys(changedValues)[0];
    if (value === "unitPrice") {
      const taxAmountPerUnit = calculateTaxAmount(changedValues.unitPrice??0, allValues.taxRatePerUnit);
      form.setFieldsValue({ taxAmountPerUnit: taxAmountPerUnit });
      form.setFieldsValue({ unitPriceIncludeTax: taxAmountPerUnit + changedValues.unitPrice??0 });
      
    }
    if (value === "taxRatePerUnit") {
      const taxAmountPerUnit = calculateTaxAmount(allValues.unitPrice??0, changedValues.taxRatePerUnit);
      form.setFieldsValue({ taxAmountPerUnit: taxAmountPerUnit });
      form.setFieldsValue({ unitPriceIncludeTax: taxAmountPerUnit + allValues.unitPrice??0 });
    }
  };

  const calculateTaxAmount = (unitPrice: number, taxRatePerUnit: number): number => {
    return (unitPrice * taxRatePerUnit) / 100;
  };

  return (
    <Form layout="vertical"
      form={form}
      onFinish={handleSubmit}
      initialValues={formData}
      onValuesChange={onValuesChange}
    >
      <Form.Item name="id" hidden={true}><Input /></Form.Item>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="code"
            label={t("Code")}
            rules={[{ required: true, message: t('Required field') ?? '' }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={16}>
          <Form.Item name="name" label={t("Name")}
            rules={[{ required: true, message: t('Required field') ?? '' }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={16}>
          <Form.Item name="shortDescription" label={t("Short Description")}>
            <Input.TextArea rows={2} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={16}>
          <Form.Item name="description" label={t("Description")}>
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="category_Ids"
            label={t("Categories")}
            rules={[{ required: true, message: t('Please select one or more') ?? '', type: 'array' }]}
          >
            <Select mode="multiple"
              options={options} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col>
          <Form.Item name="unitPrice" label={t("Unit Price")}>
            <InputNumber min={0} max={999999999} 
            // decimalSeparator={","}
            // formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}   // |(?=(,\d{0,2}))
            />
          </Form.Item>
        </Col>
        <Col >
          <Form.Item name="taxRatePerUnit" label={t("Tax Rate")}>
            <InputNumber min={0} max={15}
            // decimalSeparator={","}
            // formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}   // |(?=(,\d{0,2}))
             />
          </Form.Item>
        </Col>
        <Col >
          <Form.Item name="taxAmountPerUnit" label={t("Tax Amount")}>
            <InputNumber min={0} max={999999999} disabled 
            // decimalSeparator={","}
            // formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}   // |(?=(,\d{0,2}))
            // parser={value => `${value}`.replace(/(\.*)|/g, '')}   // |(,?)
            />
          </Form.Item>
        </Col>
        <Col >
          <Form.Item name="unitPriceIncludeTax" label={t("Unit Price Include Tax")}>
            <InputNumber min={0} max={999999999} disabled 
            // decimalSeparator={","}
            // formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}   // |(?=(,\d{0,2}))
            // parser={value => `${value}`.replace(/(\.*)|/g, '')}   // |(,?)
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="active" label={t("Active")} valuePropName="checked" hidden={(editMode != true) ? true : false}>
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Space size="middle">
            {/* <Button onClick={onClose}>{t("Cancel")}</Button> */}
            {/* Add more form fields based on the CategoryDto interface */}
            <Button type="primary" htmlType="submit">{t("Submit")}</Button>
          </Space>
        </Col>
      </Row>
    </Form>);
};