import { useTranslation } from "react-i18next";

import 'antd/dist/reset.css';
// import MyStatisticCard2 from "./MyStatisticCard2";
import MyStatisticCard3 from "./MyStatisticCard3";
import MyStatisticCard4 from "./MyStatisticCard4";
import MyStatisticCard5 from "./MyStatisticCard5";
import MyStatisticCard2 from "./MyStatisticCard2";
import { Configuration, ConfigurationParameters, PartyApi, PartyDto } from "../../api/najd";
import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { API_URL } from "../../constants";
import { useNavigate } from "react-router-dom";
import { Alert, Button } from "antd";
import { MyStatisticCard } from "./MyStatisticCard";

interface DashboardProps {
    partyInfo: PartyDto;
}
export const Dashboard: React.FC<DashboardProps> = ({ partyInfo }) => {
    const auth = useAuth();
    const [showAlert, setshowAlert] = useState<boolean>(false);
    const { t } = useTranslation();

    const token = auth.user?.access_token;
    const configParameters: ConfigurationParameters = {
        basePath: API_URL,
        accessToken: token,
        baseOptions: {
            headers: { Authorization: 'Bearer ' + token },
        }
    };
    const configuration = new Configuration(configParameters);
    // const partyApi = new PartyApi(configuration);


    useEffect(() => {
        const fetchData = async () => {
            try {
                if (partyInfo.file1 == null || partyInfo.file2 == null || partyInfo.file3 == null || partyInfo.file4 == null) {
                    if (partyInfo.code === 'patient') {
                        setshowAlert(true);

                    }
                }
            } catch (error) {
                console.log('no party found')
            }
        }
        fetchData();
    }, []); // Add refreshTrigger as a dependency

    const navigate = useNavigate();
    const handleMenuClickInfo = () => {
        navigate('/profile');
    };
    const alert = <Alert
        message={t("Alert")}
        showIcon
        description={t('Profile not complate, Access your profile page and uplaod all missing files.')}
        type="error"
        action={
            <Button size="small" danger onClick={handleMenuClickInfo}>
                {t("Press here")}
            </Button>
        }
    />;
    const emptyTag = <></>;
    return (
        <>
            {showAlert == true ? alert : emptyTag}
            <MyStatisticCard  partyInfo={partyInfo}/>
            {/* <MyStatisticCard2 /> */}
            {/* <MyStatisticCard4 /> */}
            {/* <MyStatisticCard3 /> */}
            {/* <MyStatisticCard5 /> */}
        </>
    );
};