import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Button } from "antd";
import { PageContainer, ProCard, } from '@ant-design/pro-components';
import 'antd/dist/reset.css';
import FormDrawer from "../../components/FormDrawer";
import { PartiesForm } from "./PartiesForm";
import { Configuration, ConfigurationParameters, ItemApi, LocationApi, PartyApi, PartyDto } from "../../api/najd";
import PartiesTable from "./PartiesTable";
import { API_URL } from "../../constants";
import dayjs from "dayjs";
import { useAuth } from "react-oidc-context";


interface PartiesProps {
    partyInfo: PartyDto;
}
export const Parties: React.FC<PartiesProps> = ({ partyInfo }) => {
    const { t } = useTranslation();
    const { role } = useParams(); // Access the "type" parameter
    const [editMode, setEditMode] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState<any>(null);
    const [refreshTrigger, setRefreshTrigger] = useState<boolean>(false);
    const auth = useAuth();

    const token = auth.user?.access_token;
    const configParameters: ConfigurationParameters = {
        basePath: API_URL,
        accessToken: token,
        baseOptions: {
            headers: { Authorization: 'Bearer ' + token },
        }
    };
    const configuration = new Configuration(configParameters);
    const itemApi = new ItemApi(configuration);
    const locationApi = new LocationApi(configuration);
    const partyApi = new PartyApi(configuration);

    const closeDrawer = () => {
        setDrawerOpen(false);
    };

    const handleRefresh = () => {
        setRefreshTrigger((prevState) => !prevState);
    };

    const onClickCreateNew = () => {
        // Handle create new action
        setEditMode(false);
        setSelectedRowData({
            code: role,
            serial: 1,
            partyTypeCode: 'pers',
            status: 'تسجيل جديد',
        }); // Set the selected row data in the state
        console.log('set null=>', selectedRowData);
        setDrawerOpen(true);
    };

    const onClickRowEdit = (rowData: any) => {
        if (rowData.birthDate != null)
            rowData.birthDate = dayjs(rowData.birthDate);

        setEditMode(true);
        setSelectedRowData(rowData); // Set the selected row data in the state
        setDrawerOpen(true);
    };

    const onClickSubmitButton = () => {
        // Handle the row edit action
        console.log('=> onClickSubmitButton');
        if (selectedRowData) {
            handleFormSubmit(selectedRowData);
        }
    };
    // const onSubmit = (formData: any) => {
    //     // Handle the row edit action
    //     console.log('On Submit:', formData);
    // };

    // Form Events
    const handleFormSubmit = (formData: any) => {
        // Handle form submission in the parent component
        console.log('Form Data:', formData);
        if (editMode == true) {
            partyApi.partyUpdate(formData.id, formData)
                .then(response => {
                    // Handle the API response
                    console.log('==>categoryGetList');
                    console.log(response.data);
                    closeDrawer();
                    handleRefresh();
                })
                .catch(error => {
                    // Handle the API error
                    console.error(error);
                });
        } else {
            partyApi.partyCreate(formData)
                .then(response => {
                    // Handle the API response
                    console.log('==>categoryGetList');
                    console.log(response.data);
                    closeDrawer();
                    handleRefresh();
                })
                .catch(error => {
                    // Handle the API error
                    console.error(error);
                });
        }
    };
    const handleFormChange = (formData: any) => {
        console.log('handleFormChange:', formData);
        setSelectedRowData(formData);
    };
    // End Form Events
    var page_title = '';
    switch (role) {
        case 'patient':
            page_title = t('Patients');
            break;
        case 'employee':
            page_title = t('Employees');
            break;
        case 'supplier':
            page_title = t('Suppliers');
            break;
        case 'admin':
            page_title = t('Administrators');
            break;
        default:
            page_title = t('Patients');
            break;
    }
    return (
        <PageContainer
            header={{
                title: page_title,
                ghost: true,
                breadcrumb: {
                    items: [
                        {
                            path: '/',
                            title: t("Cards"),
                        },
                        {
                            path: '/parties/' + role ?? 'party',
                            title: t(role ?? 'party'),
                        },
                    ],
                },
                extra: [
                    <Button key="3" type="primary" onClick={onClickCreateNew}>
                        {t("Add New")}
                    </Button>,
                ],
            }}
        >
            <ProCard
            >
                <PartiesTable
                    role={role}
                    partyInfo={partyInfo}
                    onClickRowEdit={onClickRowEdit}
                    refreshTrigger={refreshTrigger}
                    setRefreshTrigger={setRefreshTrigger}
                />
                <FormDrawer open={drawerOpen}
                    onClose={closeDrawer}
                    formData={selectedRowData}
                    title={((editMode) ? t("Edit") : t("Add New"))}
                    onSubmit={onClickSubmitButton}>
                    <PartiesForm
                        role={role}
                        onClose={closeDrawer}
                        formData={selectedRowData}
                        onSubmit={handleFormSubmit}
                        onChange={handleFormChange} />
                </FormDrawer>
            </ProCard>
        </PageContainer>
    );
};
