import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from 'react';
import { Button, Col, Input, Popconfirm, Row, Select, SelectProps, Space, Table, Tabs, TabsProps, Tag } from 'antd';
import type { ColumnsType, } from 'antd/es/table';
import { DeleteOutlined, EditOutlined, FilePdfOutlined } from '@ant-design/icons';
import { getFullDateTime } from "../../helper";
import { API_URL, Report_URL } from "../../constants";
import { Configuration, ConfigurationParameters, PartyDto } from "../../api/najd";
import { DocumentApi, DocumentDto, LocationApi, PartyApi } from "../../api/najd";
import { useAuth } from "react-oidc-context";
import { ColumnFilterItem } from "antd/lib/table/interface";

interface RepatedDocumentsTableProps {
    type: any;
    partyInfo: PartyDto;
    refreshTrigger: boolean;
    setRefreshTrigger: React.Dispatch<React.SetStateAction<boolean>>;
}


const RepatedDocumentsTable: React.FC<RepatedDocumentsTableProps> = ({ type, refreshTrigger, setRefreshTrigger, partyInfo }) => {
    const { t } = useTranslation();
    const [userData, setUserData] = useState<DocumentDto[]>([]);
    const [loading, setLoading] = useState(false);
    const [location_IdFilter, setlocation_IdFilter] = useState(partyInfo.location_Id);
    const [city_IdFilter, setcity_IdFilter] = useState(partyInfo.city_Id);
    const [subdivision_IdFilter, setsubdivision_IdFilter] = useState(partyInfo.subdivision_Id);

    const [documentStatus_Code, setDocumentStatus_Code] = useState(partyInfo.code != 'patient' ? "my-approve" : "approve");
    const [statusOptions, setStatusOptions] = useState<SelectProps['options']>([]);
    const [locationOptions, setLocationOptions] = useState<SelectProps['options']>([]);

    const auth = useAuth();
    const token = auth.user?.access_token;
    const configParameters: ConfigurationParameters = {
        basePath: API_URL,
        accessToken: token,
        baseOptions: {
            headers: { Authorization: 'Bearer ' + token },
        }
    };
    const configuration = new Configuration(configParameters);
    const locationApi = new LocationApi(configuration);
    const documentApi = new DocumentApi(configuration);

    const [searchText, setSearchText] = useState('');

    const columns: ColumnsType<DocumentDto> = [
        {
            title: t('Serial'), dataIndex: 'serial', key: 'serial', width: '40px',
            filteredValue: [searchText],
            onFilter: (value, record) => {
                return (
                    String(record.serial)
                        .toLowerCase()
                        .includes(value.toString().toLowerCase()) ||
                    String(record.remark)
                        .toLowerCase()
                        .includes(value.toString().toLowerCase()) ||
                    String(record.partnerParty?.name)
                        .toLowerCase()
                        .includes(value.toString().toLowerCase())
                );
            },
        },
        {
            title: t('Patient'),
            dataIndex: 'partnerParty_Id',
            key: 'partnerParty_Id',
            width: 250,
            render: (_, record) => <span>{record.partnerParty !== null ? `${record.partnerParty?.name} ${record.partnerParty?.middleName ?? ''} ${record.partnerParty?.lastName ?? ''}` : ''}</span>,
            // render: (partnerPartyId: string) => {
            //     const party = patientsOptions?.find((party) => party.value === partnerPartyId);
            //     return party ? party.label : 'N/A';
            // },
        },
        { title: t('Remark'), dataIndex: 'remark', key: 'remark', },
        {
            title: t('Care Center'), dataIndex: 'location_Id', key: 'location_Id',
            width: 375,
            render: (_, record) => <span>{record.location !== null ? record.location?.name : ''}</span>,
        },
        {
            title: t('Creation Time'), dataIndex: 'creationTime', key: 'creationTime', render: ((date: string) => getFullDateTime(date)),
            width: 150,
        },
        {
            title: t('Repeat Request Every'), dataIndex: 'repeatTaskEvery', key: 'repeatTaskEvery',
            width: 150,
            render: (_, record) => <span>{t(record.repeatTaskEvery ?? '')}</span>,
        },
        {
            title: t('Action'),
            key: 'action',
            width: 100,
            render: (_, record) => (
                <Space size="middle">
                    <Popconfirm
                        title={t("الغاء جدولة")}
                        onConfirm={() => handleUnRepeat(record.id)}
                        okText={t("Yes")}
                        cancelText={t("No")}
                    >
                        <Button type="default" icon={<DeleteOutlined />} danger
                            key={'con-btn-' + record.id} />
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    const handleUnRepeat = (id: any) => {
        console.log('Clicked cancel button');
        documentApi.documentUnRepeatDocument(id)
            .then(response => {
                // Handle the API response            
                console.log(response.data);
                handleRefresh();
            })
            .catch(error => {
                // Handle the API error
                console.error(error);
            });
    };

    const handleRefresh = () => {
        setRefreshTrigger((prevState) => !prevState); // Toggle the refresh trigger
    };

    const fetchLocationOptions = () => {
        // Implement your API call here
        // Return a Promise that resolves with the options data
        return locationApi.locationGetList()
            .then((response) => response.data)
            .then((data) => data.items);
    };
    useEffect(() => {

        fetchLocationOptions()
            .then((response) => {
                if (partyInfo.subdivision_Id != null || partyInfo.city_Id != null || partyInfo.location_Id != null) {
                    console.log('==>filter by location');
                    setLocationOptions(response?.filter(x =>
                        (partyInfo.city_Id == null || x.city_Id == partyInfo.city_Id) &&
                        (partyInfo.location_Id == null || x.id == partyInfo.location_Id) &&
                        (partyInfo.subdivision_Id == null || x.city?.subdivision_Id == partyInfo.subdivision_Id)
                    )
                        .map(({ name, id }) => (
                            {
                                label: name,
                                value: id
                            }
                        )));
                } else {
                    console.log('==>no filter by location');
                    setLocationOptions(response?.map(({ name, id }) => (
                        {
                            label: name,
                            value: id
                        }
                    )));

                }
                if (partyInfo.location_Id == null) {
                    setlocation_IdFilter(response![0].id);
                }
            })
            .catch((error) => {
                console.error('Failed to fetch select options:', error);
            });

        fetchStatus()
            .then((response) => {
                var mapedOptions: SelectProps['options'] = response?.map(({ name, id }) => (
                    {
                        label: name,
                        value: id
                    }
                ));
                setStatusOptions(mapedOptions);
            })
            .catch((error) => {
                console.error('Failed to fetch select options:', error);
            });


    }, []); // Add refreshTrigger as a dependency

    useEffect(() => {

        setLoading(true);
        const fetchUserData = async () => {
            if (location_IdFilter != null) {
                try {
                    const response = await documentApi.documentGetList(
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        location_IdFilter ?? undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        true
                    );
                    setUserData(response.data.items ?? []);
                    setLoading(false);
                } catch (error) {
                    console.error(error);
                }
            }
        };
        fetchUserData();

    }, [refreshTrigger, type, documentStatus_Code, location_IdFilter]); // Add refreshTrigger as a dependency

    const fetchStatus = () => {
        // Implement your API call here
        // Return a Promise that resolves with the options data
        return documentApi.documentGetStatusList(
        )
            .then((response) => response.data)
            .then((data) => data.items);
    };
    const onChangeTab = (key: string) => {
        setDocumentStatus_Code(key);
    };
    const handleLocationChange = (value: string) => {
        setlocation_IdFilter(value);
    };
    const operations = <Select
        defaultValue={location_IdFilter}
        style={{ width: 375 }}
        onChange={handleLocationChange}
        options={locationOptions}
    />;
    return (<>
        <Row justify="space-between" align="bottom">
            <Col span={4}>
                <Input.Search
                    placeholder={t('Search') ?? ''}
                    onSearch={(value) => {
                        setSearchText(value);
                    }}
                    onChange={(e) => {
                        setSearchText(e.target.value);
                    }}
                ></Input.Search>
            </Col>
            <Col >
                {partyInfo.location_Id != null ? undefined :operations}
            </Col>
        </Row>
        <Table<DocumentDto>
            rowKey={(record) => record.id ?? ''}
            dataSource={userData}
            columns={columns}
            loading={loading}
        />
    </>
    );
};

export default RepatedDocumentsTable;