import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from 'react';
import { Button, Col, ConfigProvider, DatePicker, Divider, Form, Input, InputNumber, Row, Select, SelectProps, Space, Switch, Upload } from 'antd';
import { CategoryApi, CityApi, CityDto, Configuration, ConfigurationParameters, CountryApi, ItemApi, LocationApi, LocationDto, PartyApi, SubdivisionApi } from "../../api/najd";
import { API_URL, API_URL_Upload, Rresource_Path, phoneRegExp } from "../../constants";
import locale from 'antd/es/locale/en_US'; // Import the desired locale
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useAuth } from "react-oidc-context";
import { RuleObject } from "antd/lib/form";

interface FormDrawerProps {
  role: any;
  formData: any; // Prop to receive the rowData
  onClose: () => void;
  onSubmit: (formData: any) => void;
  onChange: (formData: any) => void;
}
export const PartiesForm: React.FC<FormDrawerProps> = ({ role, formData, onSubmit, onChange, onClose }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm(); // Create a form instance using the useForm hook

  useEffect(() => {
    form.resetFields(); // Set initial form values when rowData changes
    form.setFieldsValue(formData); // Set initial form values when rowData changes
    if (formData.birthDate != null) {
      const calculatedAge = calculateAge(formData.birthDate);
      setAge(calculatedAge);
    } else {
      setAge(0);
    }
  }, [form, formData]);

  // const normFile = (e: any) => {
  //   console.log('Upload event:', e);
  //   if (Array.isArray(e)) {
  //     return e;
  //   }
  //   return e?.fileList;
  // };

  const [options, setOptions] = useState<SelectProps['options']>([]);
  const [itemCategoryOptions, setItemCategoryOptions] = useState<SelectProps['options']>([]);
  const [subdivisions, setsubdivisions] = useState<SelectProps['options']>([]);
  const [cities, setcities] = useState<CityDto[]>([]);
  const [cityOptions, setCityOptions] = useState<SelectProps['options']>([]);
  const [countryOptions, setCountryOptions] = useState<SelectProps['options']>([]);
  const [locationOptions, setLocationOptions] = useState<LocationDto[]>([]);
  const [citylocationOptions, setCityLocationOptions] = useState<SelectProps['options']>([]);
  const [itemsOptions, setItemsOptions] = useState<SelectProps['options']>([]);

  const auth = useAuth();

  const token = auth.user?.access_token;
  const configParameters: ConfigurationParameters = {
    basePath: API_URL,
    accessToken: token,
    baseOptions: {
      headers: { Authorization: 'Bearer ' + token },
    }
  };
  const configuration = new Configuration(configParameters);
  const categoryApi = new CategoryApi(configuration);
  const itemApi = new ItemApi(configuration);
  const locationApi = new LocationApi(configuration);
  const partyApi = new PartyApi(configuration);
  const countryApi = new CountryApi(configuration);
  const cityApi = new CityApi(configuration);
  const subdivisionApi = new SubdivisionApi(configuration);
  /***start get options */
  useEffect(() => {
    // Fetch the select options from the API
    fetchOptions('party')
      .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.map(({ name, id }) => (
          {
            label: name,
            value: id
          }
        ));
        setOptions(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });

    fetchOptions('item')
      .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.map(({ name, id }) => (
          {
            label: name,
            value: id
          }
        ));
        setItemCategoryOptions(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });


    fetchsubdivisions()
      .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.map(({ name, id }) => (
          {
            label: name,
            value: id
          }
        ));
        setsubdivisions(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });

    fetchCities()
      .then((response) => {
        setcities(response ?? []);
        const filteredList = response?.filter((x: CityDto) => formData.subdivision_Id == null || x.subdivision_Id === formData.subdivision_Id);
        var mapedOptions: SelectProps['options'] = filteredList?.map(({ name, id }) => (
          {
            label: name,
            value: id
          }
        ));
        setCityOptions(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });

    fetchCountries()
      .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.map(({ fullName, id }) => (
          {
            label: fullName,
            value: id
          }
        ));
        setCountryOptions(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });

      // Fetch the select options from the API
    fetchLocationOptions()
    .then((response) => {
      setLocationOptions(response ?? []);
      const filteredList = response?.filter((x: LocationDto) => formData.city_Id == null || x.city_Id === formData.city_Id);
      setCityLocationOptions(filteredList?.map(({ name, id }) => (
        {
          label: name,
          value: id
        }
      )));
    })
    .catch((error) => {
      console.error('Failed to fetch select options:', error);
    });

    /*
        fetchItems()
          .then((response) => {
            var mapedOptions: SelectProps['options'] = response?.map(({ name, id }) => (
              {
                label: name,
                value: id
              }
            ));
            setItemsOptions(mapedOptions);
          })
          .catch((error) => {
            console.error('Failed to fetch select options:', error);
          });*/

  }, [formData]); // Run this effect only once when the component mounts

  const fetchOptions = (cattype: string) => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return categoryApi.categoryGetList(cattype)
      .then((response) => response.data)
      .then((data) => data.items);
  };

  const fetchsubdivisions = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return subdivisionApi.subdivisionGetList()
      .then((response) => response.data)
      .then((data) => data.items);
  };

  const fetchCities = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return cityApi.cityGetList()
      .then((response) => response.data)
      .then((data) => data.items);
  };
  const handleSubdevisionChange = (value: string) => {
    const filteredList = cities?.filter((x: CityDto) => x.subdivision_Id === value);
    console.log('==> handleValuesChange', filteredList);
    setCityOptions(filteredList.map(({ name, id }) => (
      {
        label: name,
        value: id
      }
    )));
  };

  const handleCityChange = (value: string) => {
    const filteredList = locationOptions?.filter((x: LocationDto) => x.city_Id === value);
    console.log('==> handleValuesChange', filteredList);
    setCityLocationOptions(filteredList.map(({ name, id }) => (
      {
        label: name,
        value: id
      }
    )));
  };

  const fetchCountries = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return countryApi.countryGetList()
      .then((response) => response.data)
      .then((data) => data.items);
  };
  const fetchItems = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return itemApi.itemGetList()
      .then((response) => response.data)
      .then((data) => data.items);
  };

  const fetchLocationOptions = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return locationApi.locationGetList()
      .then((response) => response.data)
      .then((data) => data.items);
  };
  /***end get options */

  // Function to handle form submission
  const handleSubmit = () => {
    form.validateFields().then(values => {
      values.file1 = normlizeFileVariable(values.file1);
      values.file2 = normlizeFileVariable(values.file2);
      values.file3 = normlizeFileVariable(values.file3);
      values.file4 = normlizeFileVariable(values.file4);

      if (values.birthDate != null)
        values.birthDate = dayjs(values?.birthDate).format("YYYY-MM-DD")

      onSubmit(values);
    });
  };
  function normlizeFileVariable(value: null | string | any): string | null {
    if (value === null) {
      return null;
    } else if (typeof value === "string") {
      // Variable is a string
      return value;
    } else if (typeof value === "object") {
      // Variable is an object
      return value.name;
    } else {
      return value;
    }
  }
  const handleValuesChange = (changedValues: any, allValues: any) => {
  };
  const hide = (name: string) => {
    switch (name) {
      case 'allowCreatePartyItem':
        if (role !== 'supplier') {
          return true;
        }
        break;
      case 'gender':
        if (role === 'supplier' || role === 'employee' || role === 'admin') {
          return true;
        }
        break;
      case 'birthDate':
        if (role === 'supplier' || role === 'employee' || role === 'admin') {
          return true;
        }
        break;
      case 'fullname':
        if (role === 'supplier' || role === 'employee' || role === 'admin') {
          return true;
        }
        break;
      case 'identifier':
        if (role === 'supplier' || role === 'employee' || role === 'admin') {
          return true;
        }
        break;
      case 'weight':
        if (role === 'supplier' || role === 'employee' || role === 'admin') {
          return true;
        }
        break;

      // case 'phoneNumber':
      //   if (role === 'supplier') {
      //     return true;
      //   }
      //   break;
      case 'email':
        if (role === 'supplier') {
          return true;
        }
        break;
      case 'Nationality':
        if (role === 'supplier' || role === 'employee' || role === 'admin') {
          return true;
        }
        break;
      case 'city_Id':
        if (role === 'zzzz') {
          return true;
        }
        break;
      case 'country_Id':
        if (role === 'supplier' || role === 'employee' || role === 'admin') {
          return true;
        }
        break;
      case 'location_Ids':
        if (role === 'zzzzs') {
          return true;
        }
        break;
      case 'relative':
        if (role === 'supplier' || role === 'employee' || role === 'admin') {
          return true;
        }
        break;
      case 'address':
        if (role === 'employee' || role === 'admin') {
          return true;
        }
        break;
      case 'category_Ids':
        if (role === 'supplier' || role === 'patient' || role === 'admin') {
          return true;
        }
        break;
      case 'itemCategory_Ids':
        if (role === 'employee' || role === 'patient' || role === 'admin') {
          return true;
        }
        break;
      case 'partyItems':
        if (role === 'employee' || role === 'patient' || role === 'admin') {
          return true;
        }
        break;
      case 'partyAttachments':
        if (role === 'employee' || role === 'supplier' || role === 'admin') {
          return true;
        }
        break;
      case 'status':
        if (role === 'employee' || role === 'supplier' || role === 'admin') {
          return true;
        }
        break;

      default:
        return false;
        break;
    }
  };

  const beforeUpload = (file: File) => {
    // Generate a timestamp for the file name
    const timestamp = Date.now();
    const newFileName = `${timestamp}${file.name.substring(file.name.lastIndexOf('.'))}`;

    // Modify the file object to use the new file name
    Object.defineProperty(file, 'name', {
      writable: true,
      value: newFileName,
    });

    // Add the timestamp to the form values
    const formValues = {
      file,
      timestamp,
    };

    // Update the form values before submitting
    form.setFieldsValue(formValues);

    return true;
  };
  const getValueFromEvent = (e: any) => {
    if (e && e.file) {
      return e.file;
    }
    return e && e.target && e.target.value;
  };
  // const getValueFromEvent = (e: any) => {
  //   console.log('=>getValueFromEvent')
  //   if (Array.isArray(e)) {
  //     return e[0] || '';
  //   }
  //   return e && e.target && e.target.value;
  // };
  const renderFileLink = (filePath: any) => {
    if (filePath != null && filePath != '') {
      return (
        <a href={Rresource_Path + filePath} target="_blank" rel="noopener noreferrer">
          {t('Download File')}
        </a>
      );
    }
    return null;
  };

  const validatePhoneNumber = async (_: RuleObject, value: string) => {
    try {
      if (value === null || value.length < 9) {
        return Promise.reject(t('Not Valid'));
      }
      const response = await partyApi.partyIsValidPhoneToUse(value, formData.id);
      const isValid = response.data;
      if (isValid) {
        return Promise.resolve();
      } else {
        return Promise.reject(t('Already Taken'));
      }
    } catch (error) {
      return Promise.reject(t('Failed to validate'));
    }
  };
  const validateIdentifier = async (_: RuleObject, value: string) => {
    try {
      if (hide('identifier')) {
        return Promise.resolve();
      }
      if (value === null || value.length < 10) {
        return Promise.reject(t('Not Valid'));
      }
      const response = await partyApi.partyIsValidIdentifierToUse(value, formData.id);
      const isValid = response.data;
      if (isValid) {
        return Promise.resolve();
      } else {
        return Promise.reject(t('Already taken'));
      }
    } catch (error) {
      return Promise.reject(t('Failed to validate'));
    }
  };

  const [age, setAge] = useState<number | null>(null);
  function calculateAge(birthDate: Date): number {
    const today = new Date();
    const birthDateDate = new Date(birthDate);
    let age = today.getFullYear() - birthDateDate.getFullYear();
    const month = today.getMonth() - birthDateDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDateDate.getDate())) {
      age--;
    }
    return age;
  }
  return (
    <Form layout="vertical" form={form} onFinish={handleSubmit} initialValues={formData} >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="id" label={t("ID")} hidden={true}>
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="serial" label="Serial" hidden={true}>
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="partyTypeCode" label="PartyTypeCode" hidden={true}>
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="code" label="Code" hidden={true}>
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={hide('fullname') ? 12 : 8}>
          <Form.Item name="name" label={t("Name")} rules={[{ required: true, message: t('Required field') ?? '' }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={8} hidden={hide('fullname')}>
          <Form.Item name="middleName" label={t("Middle Name")} >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8} hidden={hide('fullname')}>
          <Form.Item name="lastName" label={t("Last Name")}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="phoneNumber"
            label={t("Phone Number")}
            hidden={hide('phoneNumber')}
            rules={[
              {
                required: true, message: t('Required field') ?? '',
                pattern: phoneRegExp
              },
              { validator: validatePhoneNumber },
            ]} >
            <Input />
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item name="email" label={t("Email")} rules={[{ type: 'email', message: t('is not a valid email') ?? '' }]} hidden={hide('email')} >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="country_Id"
            label={t("Nationality")}
            hidden={hide('Nationality')}
          >
            <Select
              options={countryOptions} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="identifierType" label={t("Identifier Type")}
            hidden={hide('identifier')}
            rules={[
              { required: !hide('identifier'), message: t('Required field') ?? '' },
            ]}>
            <Select options={[
              { value: 'id_number', label: t('ID Number') },
              { value: 'residency_id', label: t('Residency ID') },
              { value: 'passport', label: t('Passport') },
            ]} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="identifier" label={t("Identifier")}
            hidden={hide('identifier')}
            rules={[
              { required: !hide('identifier'), message: t('Required field') ?? '' },
              { validator: validateIdentifier },
            ]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="gender"
            label={t("Gender")}
            hidden={hide('gender')}
            rules={[{ required: !hide('gender'), message: t('Required field') ?? '' }]}
          >
            <Select options={[
              { value: 'M', label: t('Male') },
              { value: 'F', label: t('Female') },
            ]} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="birthDate"
            label={t("Birth Date") + ' / ' + t("Age") + ': ' + (age && age?.toString())}
            hidden={hide('birthDate')}
            rules={[{ required: !hide('birthDate'), message: t('Required field') ?? '' }]}
            getValueFromEvent={(date) => {
              const calculatedAge = calculateAge(date.toDate());
              setAge(calculatedAge);
              return date;
            }}
          >
            {/* <ConfigProvider locale={locale}> */}
            <DatePicker format={"YYYY-MM-DD"}
            // onChange={(date) => {
            //   if (date) {
            //     const calculatedAge = calculateAge(date.toDate());
            //     setAge(calculatedAge);
            //   }
            // }}
            />
            {/* </ConfigProvider> */}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="weight"
            label={t("Weight")}
            hidden={hide('weight')}
          >
            <InputNumber />
          </Form.Item>
        </Col>
      </Row>
      {/* <Divider orientation="left" orientationMargin={50}>
        {t('Relative')}
      </Divider> */}
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item name="relative" label={t("Relative")} hidden={hide('relative')}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="relativePhoneNumber" label={t("Relative Phone Number")} hidden={hide('relative')}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="relativeIdentifier" label={t("Relative Identifier")} hidden={hide('relative')}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      {/* <Divider /> */}
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="category_Id"
            label={t("Role")}
            hidden={hide('category_Ids')}
            rules={[{ required: !hide('category_Ids'), message: t('Required field') ?? '' }]}
          >
            <Select options={options} />
          </Form.Item>
        </Col>
      </Row>
      {/* <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="category_Ids"
            label={t("Role")}
            hidden={hide('category_Ids')}
            rules={[{ required: !hide('category_Ids'), message: 'Please select one or more', type: 'array' }]}
          >
            <Select mode="multiple"
              options={options}  maxLength={1}/>
          </Form.Item>
        </Col>
      </Row> */}

      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="itemCategory_Id"
            label={t("Categories")}
            hidden={hide('itemCategory_Ids')}
            rules={[{ required: !hide('itemCategory_Ids'), message: t('Required field') ?? '' }]}
          >
            <Select
              options={itemCategoryOptions} />
          </Form.Item>
        </Col>
      </Row>


      {/* <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="itemCategory_Ids"
            label={t("Categories")}
            hidden={hide('itemCategory_Ids')}
            rules={[{ required: !hide('itemCategory_Ids'), message: t('Required field') ?? '', type: 'array' }]}
          >
            <Select mode="multiple"
              options={itemCategoryOptions} />
          </Form.Item>
        </Col>
      </Row> */}
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="subdivision_Id"
            label={t("Subdivision")}
            hidden={hide('subdivision_Id')}
            rules={[{ required: (role === 'patient' ? !hide('subdivision_Id'):false), message: t('Required field') ?? '' }]}
          >
            <Select
              allowClear={true}
              options={subdivisions} onChange={handleSubdevisionChange} />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="city_Id"
            label={t("City")}
            hidden={hide('city_Id')}
            rules={[{ required: (role === 'patient'  ?  !hide('city_Id'): false), message: t('Required field') ?? '' }]}
          >
            <Select
              allowClear={true}
              options={cityOptions} onChange={handleCityChange} />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="location_Id"
            label={t("Location")}
            hidden={hide('location_Ids')}
            rules={[{ required: (role === 'patient'  ? !hide('location_Ids') : false), message: t('Required field') ?? '' }]}
          >
            <Select
              allowClear={true}
              options={citylocationOptions} />
          </Form.Item>
        </Col>
        {/* <Col span={12}>
          <Form.Item
            name="location_Ids"
            label={t("Locations")}
            hidden={hide('location_Ids')}
            rules={[{ required: !hide('location_Ids'), message: 'Please select one or more', type: 'array' }]}
          >
            <Select mode="multiple"
              options={citylocationOptions} />
          </Form.Item>
        </Col> */}
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item name="address" label={t("Address")} hidden={hide('address')}
            rules={[{ required: !hide('address'), message: t('Required field') ?? '' }]}>
            <Input.TextArea rows={2} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="status"
            label={t("Status")}
            hidden={hide('status')}
            rules={[{ required: !hide('status'), message: t('Required field') ?? '' }]}
          >
            <Select
              options={[
                { value: 'تسجيل جديد', label: t('تسجيل جديد') },
                { value: 'مفعل', label: t('مفعل') },
                { value: 'موافقة', label: t('موافقة') },
                { value: 'طلب تغير مركز صجي', label: t('طلب تغير مركز صجي') },
                { value: 'ايقاف', label: t('ايقاف') },
              ]} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} hidden={hide('partyAttachments')}>
        <Col span={6}>
          <Form.Item
            name='file1'
            label={t('Salary Statement')}
            getValueFromEvent={getValueFromEvent}
            rules={[{ required: !hide('partyAttachments'), message: t('Required field') ?? '' }]}>
            <Upload
              action={API_URL_Upload}
              beforeUpload={beforeUpload}
              maxCount={1}
              method="post">
              <Button icon={<UploadOutlined />}>{t('Select File')}</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            {renderFileLink(formData.file1)}
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name='file2'
            label={t('Rental Contract')}
            getValueFromEvent={getValueFromEvent}
            rules={[{ required: !hide('partyAttachments'), message: t('Required field') ?? '' }]}>
            <Upload
              action={API_URL_Upload}
              beforeUpload={beforeUpload}
              maxCount={1}
              method="post">
              <Button icon={<UploadOutlined />}>{t('Select File')}</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            {renderFileLink(formData.file2)}
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name='file3'
            label={t('Patient Identity')}
            getValueFromEvent={getValueFromEvent}
            rules={[{ required: !hide('partyAttachments'), message: t('Required field') ?? '' }]}>
            <Upload
              action={API_URL_Upload}
              beforeUpload={beforeUpload}
              maxCount={1}
              method="post">
              <Button icon={<UploadOutlined />}>{t('Select File')}</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            {renderFileLink(formData.file3)}
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name='file4'
            label={t('Medical Record')}
            getValueFromEvent={getValueFromEvent}
            rules={[{ required: !hide('partyAttachments'), message: t('Required field') ?? '' }]}>
            <Upload
              action={API_URL_Upload}
              beforeUpload={beforeUpload}
              maxCount={1}
              method="post">
              <Button icon={<UploadOutlined />}>{t('Select File')}</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            {renderFileLink(formData.file4)}
          </Form.Item>
        </Col>
      </Row>
      {/* <Row gutter={16} hidden={hide('partyItems')}>
        <Col span={24}>
          {<Divider orientation="left" orientationMargin={1}>
            {t('Items')}
          </Divider>}
          <Form.List name="partyItems">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                    <Form.Item
                      {...restField}
                      name={[name, 'item_Id']}
                      rules={[{ required: true, message: 'Missing first item_Id' }]}
                    >
                      <Select placeholder="Please select categories"
                        options={itemsOptions} />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'price']}
                      rules={[{ required: true, message: 'Missing last name' }]}
                    >
                      <InputNumber />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Add Item
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
      </Row> */}
      {/* 
      <Row gutter={16} hidden={hide('partyAttachments')}>
        <Col span={24}>
          {<Divider orientation="left" orientationMargin={1}>
            {t('Attachments')}
          </Divider>}
          <Form.List name="partyAttachments">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                    <Form.Item
                      name="upload"
                      label="Upload"
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                    // extra="longgggggggggggggggggggggggggggggggggg"
                    >
                      <Upload name="logo" action="/upload.do" listType="picture">
                        <Button icon={<UploadOutlined />}>{t("Click to upload")}</Button>
                      </Upload>
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, 'type']}
                      rules={[{ required: true, message: 'Missing first item_Id' }]}
                    >
                      <Select
                        options={[
                          { value: 'others', label: t('Others') },
                          { value: 'salary-statement', label: t('Salary Statement') },
                          { value: 'rental-contract', label: t('Rental Contract') },
                          { value: 'patient-identity', label: t('Patient Identity') },
                          { value: 'medical-record', label: t('Medical Record') },
                        ]} />
                    </Form.Item>
                    <Form.Item {...restField} name={[name, 'path']} >
                      <Input />
                    </Form.Item>
                    <Form.Item {...restField} name={[name, 'fileExtension']} hidden={hide('phoneNumber')}>
                      <Input />
                    </Form.Item>
                    <Form.Item {...restField} name={[name, 'fileName']} hidden={hide('phoneNumber')}>
                      <Input />
                    </Form.Item> 
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    {t('Add file')}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
      </Row>
       */}
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="active" label={t("Active")} valuePropName="checked" hidden={(formData.id == null) ? true : false}>
            <Switch />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="allowCreatePartyItem" label={t("Allow Add Items")} valuePropName="checked" hidden={hide('allowCreatePartyItem')}>
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Space size="middle">
            {/* <Button onClick={onClose}>{t("Cancel")}</Button> */}
            {/* Add more form fields based on the CategoryDto interface */}
            <Button type="primary" htmlType="submit">{t("Submit")}</Button>
          </Space>
        </Col>
      </Row>
    </Form >);
};