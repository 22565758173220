import { StatisticCard } from '@ant-design/pro-components';
import RcResizeObserver from 'rc-resize-observer';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { Configuration, ConfigurationParameters, CountryApi, DocumentApi, ItemApi, LocationApi, PartyApi, PartyDto } from '../../api/najd';
import { API_URL } from '../../constants';

const imgStyle = {
  display: 'block',
  width: 42,
  height: 42,
};

interface MyStatisticCardProps {
  partyInfo: PartyDto;
}
export const MyStatisticCard: React.FC<MyStatisticCardProps> = ({ partyInfo }) => {
  
  const { t } = useTranslation();
  const auth = useAuth();
  const [responsive, setResponsive] = useState(false);
  const [documentCount, setdocumentCount] = useState(0);
  const [patientCount, setpatientCount] = useState(0);
  const [itemCount, setitemCount] = useState(0);
  const [locationCount, setlocationCount] = useState(0);

  const token = auth.user?.access_token;
  const configParameters: ConfigurationParameters = {
    basePath: API_URL,
    accessToken: token,
    baseOptions: {
      headers: { Authorization: 'Bearer ' + token },
    }
  };
  const configuration = new Configuration(configParameters);
  const documentApi = new DocumentApi(configuration);
  const partyApi = new PartyApi(configuration);
  const itemApi = new ItemApi(configuration);
  const locationApi = new LocationApi(configuration);

  const partnerParty_Id: string | null | undefined = partyInfo.code === 'patient' ? partyInfo.id : null;
  const supplier_Id: string | null | undefined = partyInfo.code === 'supplier' ? partyInfo.id : null;
  const consultant_Id: string | null | undefined = partyInfo.code === 'consultant' ? partyInfo.id : null;
  
  const showPatientount = partyInfo.code !== 'patient' && partyInfo.code !== 'supplier' ? true : false;
  const showItemCount = partyInfo.code === 'admin' || partyInfo.code === 'po'? true : false;
  const showCareCentersCount = partyInfo.code !== 'patient' && partyInfo.code !== 'supplier' ? true : false;
  

  useEffect(() => {
    const fetchData = async () => {
      var response_1 = await documentApi.documentCount(
        undefined,
        undefined,
        partnerParty_Id??undefined,
        undefined,
        supplier_Id??undefined,
        consultant_Id??undefined,
        undefined,
        undefined,
        undefined,
        partyInfo.location_Id??undefined
      );
      setdocumentCount(response_1.data ?? 0);
      
      var response_2 = await partyApi.partyCount(
        undefined,
        'patient',
        undefined,
        undefined,
        undefined,
        undefined,
        partyInfo.location_Id??undefined
      );
      setpatientCount(response_2.data ?? 0);
      
      var response_3 = await itemApi.itemCount();
      setitemCount(response_3.data ?? 0);
      
      var response_4 = await locationApi.locationCount();
      setlocationCount(response_4.data ?? 0);
    }
    fetchData();
  }, []); // Add refreshTrigger as a dependency

  return (
    <RcResizeObserver
      key="resize-observer"
      onResize={(offset) => {
        setResponsive(offset.width < 596);
      }}
    >
      <StatisticCard.Group direction={responsive ? 'column' : 'row'}>
        <StatisticCard
          statistic={{
            title: t('Request Count'),
            value: documentCount,
            icon: (
              <img
                style={imgStyle}
                src="https://gw.alipayobjects.com/mdn/rms_7bc6d8/afts/img/A*FPlYQoTNlBEAAAAAAAAAAABkARQnAQ"
                alt="icon"
              />
            ),
          }}
        />
        {showPatientount == true ? <StatisticCard
          statistic={{
            title: t('Patient Count'),
            value: patientCount,
            icon: (
              <img
                style={imgStyle}
                src="https://gw.alipayobjects.com/mdn/rms_7bc6d8/afts/img/A*FPlYQoTNlBEAAAAAAAAAAABkARQnAQ"
                alt="icon"
              />
            ),
          }}
        /> : <></>}
        {showItemCount == true ?
        <StatisticCard
          statistic={{
            title: t('Items Count'),
            value: itemCount,
            icon: (
              <img
                style={imgStyle}
                src="https://gw.alipayobjects.com/mdn/rms_7bc6d8/afts/img/A*FPlYQoTNlBEAAAAAAAAAAABkARQnAQ"
                alt="icon"
              />
            ),
          }}
        />
        : <></>}
        {showCareCentersCount == true ?
        <StatisticCard
          statistic={{
            title: t('Care Centers Count'),
            value: locationCount,
            icon: (
              <img
                style={imgStyle}
                src="https://gw.alipayobjects.com/mdn/rms_7bc6d8/afts/img/A*FPlYQoTNlBEAAAAAAAAAAABkARQnAQ"
                alt="icon"
              />
            ),
          }}
        />
        : <></>}
      </StatisticCard.Group>
    </RcResizeObserver>
  );
};