import { useTranslation } from "react-i18next";
import { Button, Dropdown } from "antd";
import { PageContainer, ProCard, } from '@ant-design/pro-components';

import 'antd/dist/reset.css';
import { Dashboard } from "./dashboard/Dashboard";
import { PartyDto } from "../api/najd";

interface HomeProps {
  partyInfo: PartyDto;
}
export const Home: React.FC<HomeProps> = ({ partyInfo }) => {
  const { t } = useTranslation();

  return (
    <PageContainer
      header={{
        title: t('Home'),
        ghost: true,
        // breadcrumb: {
        //     items: [
        //         {
        //             path: '',
        //             title: 'خيار 1',
        //         },
        //         {
        //             path: '',
        //             title: 'خيار2 ',
        //         },
        //         {
        //             path: '',
        //             title: 'خيار 3',
        //         },
        //     ],
        // },
      }}>
      <ProCard>
        <Dashboard partyInfo={partyInfo} /> 
      </ProCard>


    </PageContainer>
  );
};
