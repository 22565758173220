import { ProDescriptions } from '@ant-design/pro-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { API_URL, Report_URL, Rresource_Path } from '../../constants';
import { Button, Checkbox, Form, Input, Modal, SelectProps, Space, Table } from 'antd';
import { ChangePasswordInput, Configuration, ConfigurationParameters, DocumentApi, DocumentDto, IdentityUserDto, IdentityUserUpdateDto, PartyApi, PartyDto, UserApi } from '../../api/najd';
import { RuleObject } from 'antd/lib/form';
import { ColumnsType } from 'antd/lib/table/interface';
import { getFullDateTime } from '../../helper';
import { FilePdfOutlined } from '@ant-design/icons';


interface PartyDocumentsProps {
  party_id: any;
  open: boolean;
  onCancel: () => void;
}

interface Values {
  title: string;
  description: string;
  modifier: string;
}


export const PartyDocuments: React.FC<PartyDocumentsProps> = ({ party_id, open, onCancel }) => {
  const [tableData, settableData] = useState<DocumentDto[]>([]);
  const [loading, setLoading] = useState(false);
  const auth = useAuth();
  const { t } = useTranslation();

  const token = auth.user?.access_token;
  const configParameters: ConfigurationParameters = {
    basePath: API_URL,
    accessToken: token,
    baseOptions: {
      headers: { Authorization: 'Bearer ' + token },
    }
  };
  const configuration = new Configuration(configParameters);
  const documentApi = new DocumentApi(configuration);


  useEffect(() => {
    const fetchUserData = async () => {
      documentApi.documentGetList( undefined,
        undefined,
        party_id ?? undefined)
        .then(response => {
          settableData(response.data.items??[]);
        })
        .catch(error => {
          // Handle the API error
          console.error(error);
          settableData([]);
        });

    };
    fetchUserData();
  }, [party_id]); // Add refreshTrigger as a dependency


  const columns: ColumnsType<DocumentDto> = [
    // {
    //     title: t('Code'), dataIndex: 'code', key: 'code',
    // },
    { title: t('Serial'), dataIndex: 'serial', key: 'serial', width: 50, },
    { title: t('Remark'), dataIndex: 'remark', key: 'remark', 
    width: 250,},
    // { title: t('Date'), dataIndex: 'docDate', key: 'docDate', },
    {
        title: t('Care Center'), dataIndex: 'location_Id', key: 'location_Id',
        width: 150,
        render: (_, record) => <span>{record.location !== null ? record.location?.name : ''}</span>,
    },
    {
        title: t('Status'),
        dataIndex: 'documentStatus_Id',
        key: 'documentStatus_Id',
        width: 150,
        render: (_, record) => <span>{record.documentStatus !== null ? record.documentStatus?.name : ''}</span>,
    },
    {
        title: t('Creation Time'), dataIndex: 'creationTime', key: 'creationTime', render: ((date: string) => getFullDateTime(date)),
        width: 150,
    },
    {
        title: t('Action'),
        key: 'action',
        width: 50,
        render: (_, record) => (
            <Space size="middle">

                <Button type="default"  title={t('PDF')??''} icon={<FilePdfOutlined />}
                    key={'pdf-btn-' + record.id} onClick={() => {
                        const url: string = `${Report_URL}/report/ExportDoc?document_id=${record.id ?? ''}&format=pdf&fileName=Request-${record.serial ?? 0}`;
                        const newTab = window.open(url, '_blank');
                        if (newTab) {
                            newTab.focus();
                        } else {
                            // If the new tab was blocked by the browser's popup blocker,
                            // you can provide a fallback or display an error message.
                            console.error('Failed to open new tab. Please allow popups for this site.');
                        }

                    }} />
            </Space>
        ),
    },
];

  return (
    <Modal

      width={1000}
      open={open}
      title={t("Paient Requests")}
      okButtonProps={{ disabled: true }}
      cancelButtonProps={{ disabled: true }}
      footer={[

      ]}
      onCancel={()=>{
        onCancel();
      }}
    >
     <Table<DocumentDto>
            rowKey={(record) => record.id ?? ''}
            dataSource={tableData}
            columns={columns}
            loading={loading}
        />
    </Modal>
  );
};