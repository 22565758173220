import { useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, ConfigProvider, DatePicker, Divider, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, SelectProps, Space, Switch, Table, Upload, message } from 'antd';
import { CatalogApi, CategoryApi, CityApi, Configuration, ConfigurationParameters, CountryApi, DocumentApi, DocumentItemDto, ItemApi, ItemDto, LocationApi, PartyApi, PartyDto } from "../../api/najd";
import { API_URL } from "../../constants";
import locale from 'antd/es/locale/en_US'; // Import the desired locale
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { EditableFormInstance, EditableProTable, ProColumns } from "@ant-design/pro-components";
import { PartyInfoCard } from "../parties/PartyInfoCard";
import { useAuth } from "react-oidc-context";
import DocumentsFormItems from "./DocumentsFormItems";

interface FormDrawerProps {
  type: any;
  partyInfo: PartyDto;
  formData: any; // Prop to receive the rowData
  onClose: () => void;
  onSubmit: (formData: any) => void;
  onReject: (formData: any) => void;
  onApprove: (formData: any) => void;
  onChange: (formData: any) => void;
}
export const DocumentsForm: React.FC<FormDrawerProps> = ({ type, formData, onSubmit, onChange, onClose, partyInfo, onReject, onApprove }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm(); // Create a form instance using the useForm hook
  const [documentItems, setDocumentItems] = useState<DocumentItemDto[] | undefined>();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(formData); // Set initial form values when rowData changes
    setDocumentItems(formData.documentItems ?? [])
  }, [form, formData]);

  const normFile = (e: any) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const [options, setOptions] = useState<SelectProps['options']>([]);
  const [itemCategoryOptions, setItemCategoryOptions] = useState<SelectProps['options']>([]);
  const [documentCategoryOptions, setDocumentCategoryOptions] = useState<SelectProps['options']>([]);
  const [cityOptions, setCityOptions] = useState<SelectProps['options']>([]);
  const [countryOptions, setCountryOptions] = useState<SelectProps['options']>([]);
  const [locationOptions, setLocationOptions] = useState<SelectProps['options']>([]);
  const [itemsOptions, setItemsOptions] = useState<ItemDto[]>([]);
  const [patientsOptions, setPatientsOptions] = useState<SelectProps['options']>([]);
  const [supplierOptions, setSupplierOptions] = useState<SelectProps['options']>([]);
  const [employeeOptions, setEmployeeOptions] = useState<SelectProps['options']>([]);
  const [statusOptions, setStatusOptions] = useState<SelectProps['options']>([]);

  const auth = useAuth();
  const token = auth.user?.access_token;
  const configParameters: ConfigurationParameters = {
    basePath: API_URL,
    accessToken: token,
    baseOptions: {
      headers: { Authorization: 'Bearer ' + token },
    }
  };
  const configuration = new Configuration(configParameters);

  const categoryApi = new CategoryApi(configuration);
  const itemApi = new ItemApi(configuration);
  const locationApi = new LocationApi(configuration);
  const partyApi = new PartyApi(configuration);
  const documentApi = new DocumentApi(configuration);
  const countryApi = new CountryApi(configuration);
  const cityApi = new CityApi(configuration);

  /***start get options */
  useEffect(() => {
    // Fetch the select options from the API
    fetchOptions('party')
      .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.map(({ name, id }) => (
          {
            label: name,
            value: id
          }
        ));
        setOptions(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });

    fetchOptions('document')
      .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.map(({ name, id }) => (
          {
            label: name,
            value: id
          }
        ));
        setDocumentCategoryOptions(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });

    fetchOptions('item')
      .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.map(({ name, id }) => (
          {
            label: name,
            value: id
          }
        ));
        setItemCategoryOptions(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });


    fetchCities()
      .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.map(({ name, id }) => (
          {
            label: name,
            value: id
          }
        ));
        setCityOptions(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });

    fetchCountries()
      .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.map(({ fullName, id }) => (
          {
            label: fullName,
            value: id
          }
        ));
        setCountryOptions(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });

    fetchItems()
      .then((response) => {
        setItemsOptions(response ?? []);
        // var mapedOptions: SelectProps['options'] = response?.map(({ name, id }) => (
        //   {
        //     label: name,
        //     value: id
        //   }
        // ));
        // setItemsOptions(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });
    fetchPateients()
      .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.map(({ name, id }) => (
          {
            label: name,
            value: id
          }
        ));
        setPatientsOptions(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });

    fetchSupplierO()
      .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.map(({ name, id }) => (
          {
            label: name,
            value: id
          }
        ));
        setSupplierOptions(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });

    fetchEmployee()
      .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.map(({ name, id }) => (
          {
            label: name,
            value: id
          }
        ));
        setEmployeeOptions(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });

    fetchStatus()
      .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.map(({ name, id }) => (
          {
            label: name,
            value: id
          }
        ));
        setStatusOptions(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });
  }, [formData]); // Run this effect only once when the component mounts

  const fetchStatus = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return documentApi.documentGetStatusList()
      .then((response) => response.data)
      .then((data) => data.items);
  };
  const fetchPateients = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return partyApi.partyGetList('', 'patient')
      .then((response) => response.data)
      .then((data) => data.items);
  };
  const fetchEmployee = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return partyApi.partyGetAssginableList('', 'consultant', '', '', '', '', formData.location_Id, formData.location.city_Id)
      .then((response) => response.data)
      .then((data) => data.items);
  };
  const fetchSupplierO = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return partyApi.partyGetAssginableList('', 'supplier', '', '', '', '', formData.location_Id, formData.location.city_Id)
      .then((response) => response.data)
      .then((data) => data.items);
  };

  const fetchOptions = (cattype: string) => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return categoryApi.categoryGetList(cattype)
      .then((response) => response.data)
      .then((data) => data.items);
  };

  const fetchCities = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return cityApi.cityGetList()
      .then((response) => response.data)
      .then((data) => data.items);
  };

  const fetchCountries = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return countryApi.countryGetList()
      .then((response) => response.data)
      .then((data) => data.items);
  };
  const fetchItems = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return itemApi.itemGetList()
      .then((response) => response.data)
      .then((data) => data.items);
  };
  /***end get options */
  /***start get options */
  useEffect(() => {
    // Fetch the select options from the API
    fetchLocationOptions()
      .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.map(({ name, id }) => (
          {
            label: name,
            value: id
          }
        ));
        setLocationOptions(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });
  }, []); // Run this effect only once when the component mounts

  const fetchLocationOptions = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return locationApi.locationGetList()
      .then((response) => response.data)
      .then((data) => data.items);
  };
  /***end get options */

  // Function to handle form submission
  const handleSubmit = () => {
    form.validateFields().then(values => {
      values.documentItems = documentItems;
      onSubmit(values);
    });
  };
  const cancel = () => {
    documentApi.documentCancel(formData.id)
      .then(response => {
        // Handle the API response
        onClose();
        console.log(response.data);
      })
      .catch(error => {
        // Handle the API error
        console.error(error);
        message.error(t('Failed to submit'));
      });
  };


  const handleValuesChange = (changedValues: any, allValues: any) => {
    onChange(allValues);
  };
  const hide = (name: string) => {
    return false;
  };

  /*
  // Function to handle item change
  const handleItemChange = (value: any, record: DocumentItemDto) => {
    const selectedItem = itemsOptions.find((option) => option.value === value);
    if (selectedItem) {
      // Update the unitPrice for the selected item
      record.unitPrice = selectedItem.unitPrice;
    }
    // Update the dataSource with the new record
    setDataSource([...dataSource]);
  };
  */
  //////////////////////////
  const rejects = () => {
    setinputRejectCommentValue('');
    setopenConfirmReject(true);
  };
  const [openConfirmReject, setopenConfirmReject] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [inputRejectCommentValue, setinputRejectCommentValue] = useState('');

  const handleOkReject = () => {
    if (inputRejectCommentValue === undefined || inputRejectCommentValue === null || inputRejectCommentValue === "") {
      message.error(t("Enter the reason for rejection"));
      return false;
    }
    setConfirmLoading(true);
    documentApi.documentReject(formData.id, inputRejectCommentValue)
      .then(response => {
        setConfirmLoading(false);
        setopenConfirmReject(false);
        onClose();
      })
      .catch(error => {
        setinputRejectCommentValue('');
        // Handle the API error
        message.error(t('Failed to submit'));
        console.error(error);
      });

  };
  const handleCancel = () => {
    console.log('Clicked cancel button');
    setopenConfirmReject(false);
  };
  const handleInputChange = (e: any) => {
    setinputRejectCommentValue(e.target.value);
  };
  /////////////////////////////

  const returnDoc = () => {
    setinputReturnDocCommentValue('');
    setopenConfirmReturnDoc(true);
  };

  const [openConfirmReturnDoc, setopenConfirmReturnDoc] = useState(false);
  const [confirmReturnDocLoading, setConfirmReturnDocLoading] = useState(false);
  const [inputReturnDocCommentValue, setinputReturnDocCommentValue] = useState('');

  const handleOkReturnDoc = () => {
    if (inputReturnDocCommentValue === undefined || inputReturnDocCommentValue === null || inputReturnDocCommentValue === "") {
      message.error(t("Enter the reason for the return"));
      return false;
    }
    setConfirmReturnDocLoading(true);
    documentApi.documentReturnDoc(formData.id, inputReturnDocCommentValue)
      .then(response => {
        setConfirmReturnDocLoading(false);
        setopenConfirmReturnDoc(false);
        onClose();
      })
      .catch(error => {
        setConfirmReturnDocLoading(false);
        // Handle the API error
        message.error(t('Failed to submit'));
        console.error(error);
      });

  };
  const handleCancelReturnDoc = () => {
    console.log('Clicked cancel button');
    setopenConfirmReturnDoc(false);
  };
  const handleInputChangeReturnDoc = (e: any) => {
    setinputReturnDocCommentValue(e.target.value);
  };
  /////////////////////////////
  const approve = () => {
    setinputApproveCommentValue('');
    setopenConfirmApprove(true);
  };

  const [openConfirmApprove, setopenConfirmApprove] = useState(false);
  const [confirmApproveLoading, setConfirmApproveLoading] = useState(false);
  const [inputApproveCommentValue, setinputApproveCommentValue] = useState('');

  const handleOkApprove = () => {
    setConfirmApproveLoading(true);
    // validate form
    form.validateFields().then(values => {
      values.documentItems = documentItems;
      onApprove(values)
      // send update form
      documentApi.documentUpdate(formData.id, formData)
        .then(response => {
          // Handle the API response
          console.log(response.data);
          message.success(t('Submit Successfully'));
          // send approve
          documentApi.documentApprove(formData.id, inputApproveCommentValue)
            .then(response => {
              setConfirmApproveLoading(false);
              setopenConfirmApprove(false);
              onClose();
            })
            .catch(error => {
              setConfirmApproveLoading(false);
              // Handle the API error
              message.error(t('Failed to submit'));
              console.error(error);
            });

        })
        .catch(error => {
          setConfirmApproveLoading(false);
          // Handle the API error
          console.error(error);
        });



    }).catch(e => {
      console.log(e);
    });
  };
  const handleCancelApprove = () => {
    console.log('Clicked cancel button');
    setopenConfirmApprove(false);
  };
  const handleInputChangeApprove = (e: any) => {
    setinputApproveCommentValue(e.target.value);
  };
  ////////////////


  return (
    <>
      <PartyInfoCard
        party_id={formData.partnerParty_Id}
      />

      <Form layout="vertical" form={form} onFinish={handleSubmit} initialValues={formData} >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="id" label="ID" hidden={true}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="code" label="Code" hidden={true}>
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>

            <Row gutter={16}>

              <Col span={6}>
                <Form.Item
                  name="category_Id"
                  label={t("Request Type")}
                  hidden={hide('category_Ids')}
                  rules={[{ required: true, message: t('Required field') ?? '' }]}
                >
                  <Select options={documentCategoryOptions} disabled={formData.documentCategories_ReadOnly} />
                </Form.Item>
              </Col>

              {/* <Col span={6}>
                <Form.Item
                  name="category_Ids"
                  label={t("Request Type")}
                  rules={[{ required: true, message: 'Please select one or more', type: 'array' }]}
                >
                  <Select mode="multiple" disabled={formData.documentCategories_ReadOnly}
                    options={documentCategoryOptions} />
                </Form.Item>
              </Col> */}
              <Col span={6}>
                <Form.Item name="salesParty_Id" label={t("Consultant")}
                  rules={[{ required: true, message: t('Required field') ?? '' }]}>
                  <Select options={employeeOptions} disabled={formData.salesParty_Id_ReadOnly} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="supplierParty_Id"
                  label={t("Supplier")}
                  rules={[{ required: partyInfo.code === 'po' ? true : false, message: t('Required field') ?? '' }]}>
                  <Select options={supplierOptions} disabled={formData.supplierParty_Id_ReadOnly} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="documentStatus_Id" label={t("Status")} >
                  <Select options={statusOptions} disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="remark" label={t("Remark")} >
                  <Input.TextArea rows={2} placeholder={t("please enter requests details") ?? ''} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="repeatTask" label={t("Repeat Request")} valuePropName="checked" >
                  <Switch disabled />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="repeatTaskEvery"
                  label={t("Repeat Request Every")}
                >
                  <Select disabled options={[
                    // { value: 'Daily', label: t('Daily') },
                    // { value: 'Weekly', label: t('Weekly') },
                    { value: 'Monthly', label: t('Monthly') },
                    { value: '2Month', label: t('2Month') },
                    { value: '3Month', label: t('3Month') },
                  ]} />
                </Form.Item>
              </Col>
            </Row>
          </Col>

        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name='documentItems'>
              {documentItems != undefined ? <DocumentsFormItems
                documentItems_ReadOnly={formData.documentItems_ReadOnly}
                itemsOptions={itemsOptions}
                inital_dataSource={documentItems}
                onSetDataSource={function (documentItemsTableValues: DocumentItemDto[]): void {
                  console.log('==>onSetDataSource', documentItemsTableValues);
                  // setDocumentItems(prevState => [...prevState, ...dataSource]);
                  setDocumentItems(documentItemsTableValues);
                }} /> : <span>No data</span>}

            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={18}>
            <Space size="middle">
              {/* <Button onClick={onClose}>{t("Cancel")}</Button> */}
              {/* Add more form fields based on the CategoryDto interface */}
              <Button type="primary" htmlType="submit" hidden={!(formData.allowUpdate ?? false)}>{t("Submit")}</Button>
            </Space>
          </Col>
          <Col span={4}>
            <Space size="middle">
              <Button type="dashed" danger onClick={cancel} hidden={!(formData.allowCancel ?? false)}>{t("Cancel")}</Button>
              <Popconfirm
                title={
                  <div>
                    {t("هل انت متاكد؟")}
                    <Input
                      value={inputRejectCommentValue}
                      onChange={handleInputChange}
                      style={{ marginTop: '8px' }}
                    />
                  </div>
                }
                description={t("To reject the application, you must explain the reason")}
                open={openConfirmReject}
                onConfirm={handleOkReject}
                okButtonProps={{ loading: confirmLoading }}
                onCancel={handleCancel}
                okText={t("Yes")}
                cancelText={t("No")}
              >
                <Button type="dashed" danger onClick={rejects} hidden={!(formData.allowReject ?? false)}>{t("Reject")}</Button>
              </Popconfirm>
              <Popconfirm
                title={
                  <div>
                    {t("هل انت متاكد؟")}
                    <Input
                      value={inputReturnDocCommentValue}
                      onChange={handleInputChangeReturnDoc}
                      style={{ marginTop: '8px' }}
                    />
                  </div>
                }
                description={t("To return the application, you must explain the reason")}
                open={openConfirmReturnDoc}
                onConfirm={handleOkReturnDoc}
                okButtonProps={{ loading: confirmReturnDocLoading }}
                onCancel={handleCancelReturnDoc}
                okText={t("Yes")}
                cancelText={t("No")}
              >
                <Button type="dashed" danger onClick={returnDoc} hidden={!(formData.allowReturn ?? false)}>{t("Return")}</Button>
              </Popconfirm>
              <Popconfirm
                title={
                  <div>
                    {t("هل انت متاكد؟")}
                    <Input
                      value={inputApproveCommentValue}
                      onChange={handleInputChangeApprove}
                      style={{ marginTop: '8px' }}
                    />
                  </div>
                }
                description={t("You can add a comment to the approval request")}
                open={openConfirmApprove}
                onConfirm={handleOkApprove}
                okButtonProps={{ loading: confirmApproveLoading }}
                onCancel={handleCancelApprove}
                okText={t("Yes")}
                cancelText={t("No")}
              >
                <Button type="primary" onClick={approve} hidden={!(formData.allowApprove ?? false)}>{t("Approve")}</Button>
              </Popconfirm>
            </Space>
          </Col>
        </Row>
      </Form >
    </>);
};