import { useTranslation } from "react-i18next";
import React, { useRef, useState } from 'react';
import { Button, Table } from 'antd';
import { DocumentItemDto, } from "../../api/najd";
import { EditableFormInstance, EditableProTable, ProColumns } from "@ant-design/pro-components";

type DocumentsFormItemsProps = {
  itemsOptions?: any[];
  inital_dataSource: DocumentItemDto[];
  documentItems_ReadOnly: boolean;
  onSetDataSource: (dataSource: DocumentItemDto[]) => void;
};

const DocumentsFormItems: React.FC<DocumentsFormItemsProps> = ({ itemsOptions, inital_dataSource, onSetDataSource, documentItems_ReadOnly }) => {
  const { t } = useTranslation();

  const editableFormRef = useRef<EditableFormInstance>();
  // Define your table columns
  const columns: ProColumns<DocumentItemDto>[] = [
    // ... define your columns here
    {
      title: t('Item'),
      dataIndex: 'item_Id',
      key: 'item_Id',
      valueEnum: itemsOptions?.reduce((enumObj: any, option: any) => {
        enumObj[option.id] = { text: option.name };
        return enumObj;
      }, {}),
      fieldProps: (formRow, { rowIndex, rowKey }) => {
        /*   return {
             onSelect: (val: any) => {
               console.log('======x>');
               const selectItem = itemsOptions?.find((status_item) => status_item.id === val);
               //update price
               editableFormRef.current?.setRowData?.(rowIndex, { unitPrice: parseFloat((selectItem?.unitPrice ?? 0).toFixed(2)) });
             },
           };
           */
        return {
          showSearch: true, // Enable search
          // optionFilterProp: "children",
          onChange: (val: any) => {
            const selectItem = itemsOptions?.find((status_item) => status_item.id === val);
            if (selectItem) {
              let newData: any = [...dataSource]; // copy the existing data
              newData[rowIndex] = { // update the specific row
                ...newData[rowIndex],
                unitPrice: parseFloat((selectItem?.unitPrice ?? 0).toFixed(2)),
              };
              editableFormRef.current?.setRowData?.(rowIndex, {
                unitPrice: parseFloat((selectItem?.unitPrice ?? 0).toFixed(2)),
                taxRatePerUnit: parseFloat((selectItem?.taxRatePerUnit ?? 0).toFixed(2)),
                taxAmountPerUnit: parseFloat((selectItem?.taxAmountPerUnit ?? 0).toFixed(2)),
              });
              setDataSource(newData); // update the state
              formRow.setFieldsValue({
                unitPrice: parseFloat((selectItem?.unitPrice ?? 0).toFixed(2)),
                taxRatePerUnit: parseFloat((selectItem?.taxRatePerUnit ?? 0).toFixed(2)),
              });
            }
          },
        };
      },
      // filters: true,
      // onFilter: true,
      // ellipsis: true,
      hideInTable: false,
      hideInForm: false,
      hideInSearch: false,
      valueType: 'select',
      // formItemProps: { showSearch: true }
      // valueType: (item) => ({
      //   type: 'select',
      //   status: 'active',
      //   Select,
      //   // status: item.basePrice !== 'error' ? 'active' : 'exception',
      // }),
    },
    { title: t('Quantity'), dataIndex: 'quantity', key: 'quantity', width: 125 },
    { title: t('Price'), dataIndex: 'unitPrice', key: 'unitPrice', width: 125 },
    { title: t('Tax Rate'), dataIndex: 'taxRatePerUnit', key: 'taxRatePerUnit', width: 125 },
    {
      title: t('Total Tax Amount'), dataIndex: 'totalTaxAmount', key: 'totalTaxAmount',
      editable: false,
      render: (_, record) => {
        const { quantity, unitPrice, taxRatePerUnit } = record;
        const totalTaxAmount = calc_totalTaxAmount(quantity ?? 0, unitPrice ?? 0, taxRatePerUnit ?? 0);
        return <span>{parseFloat(totalTaxAmount.toFixed(2))}</span>;
      }, width: 125
    },

    {
      title: t('Total Price'),
      dataIndex: 'totalPrice',
      valueType: 'money',
      editable: false,
      render: (_, record) => {
        const { quantity, unitPrice, taxRatePerUnit } = record;
        const totalPrice = calc_totaAmount(quantity ?? 0, unitPrice ?? 0, taxRatePerUnit ?? 0);
        return <span>{parseFloat((totalPrice).toFixed(2))}</span>;
      }, width: 125
    },
  ];
  if (documentItems_ReadOnly != true) {
    columns.splice(6, 0, {
      title: t('Action'),
      valueType: 'option',
      width: 200,
      render: (text, record, _, action) => [
        <a
          key="editable"
          onClick={() => {
            action?.startEditable?.(record.id ?? '');
          }}
        >
          {t('Edit')}
        </a>,
        <a
          key="delete"
          onClick={() => {
            setDataSource(dataSource.filter((item) => item.id !== record.id));
            onSetDataSource([...dataSource]);
          }}
        >
          {t('Delete')}
        </a>,
      ],
    });
  }

  function calc_totalNetPrice(quantity: number, unitPrice: number) {
    const totalNetPrice = (quantity ?? 0) * (unitPrice ?? 0);
    return totalNetPrice;
  }
  function calc_totalTaxAmount(quantity: number, unitPrice: number, taxRatePerUnit: number) {
    const totalNetPrice = calc_totalNetPrice(quantity, unitPrice);
    const totalTaxAmount = (taxRatePerUnit ?? 0) > 0 ? (totalNetPrice ?? 0) * ((taxRatePerUnit ?? 0) / 100) : 0;
    return totalTaxAmount;
  }
  function calc_totaAmount(quantity: number, unitPrice: number, taxRatePerUnit: number) {
    const totalNetPrice = calc_totalNetPrice(quantity, unitPrice);
    const totalTaxAmount = calc_totalTaxAmount(quantity, unitPrice, taxRatePerUnit);
    return totalTaxAmount + totalNetPrice;
  }


  // Define your table data
  const [editableKeys, setEditableRowKeys] = useState<React.Key[]>([]);
  const [dataSource, setDataSource] = useState<readonly DocumentItemDto[]>(inital_dataSource);
  const handleTableChange = (updatedData: readonly DocumentItemDto[]) => {
    // const updatedData = newData.map((record) => {
    //   const { quantity, price } = record;
    //   const totalPrice = quantity * price;
    //   return { ...record, totalPrice };
    // });
    console.log('==>handleTableChange')
    setDataSource(updatedData);
    onSetDataSource([...updatedData]);
  };

  const [refreshKey, setRefreshKey] = useState(0);
  function addNewRow() {
    const newRow: DocumentItemDto = { id: (Math.random() * 1000000).toFixed(0).toString(), item_Id: null, unitPrice: 0, quantity: 1, totalPrice: 0 };
    setDataSource(prevState => [...prevState, newRow]);
    onSetDataSource([...dataSource, newRow]);
    refreshTable();
  }
  // Call this function to force a re-render
  function refreshTable() {
    setRefreshKey(prevKey => prevKey + 1);
  }
  return (<EditableProTable<DocumentItemDto>
    toolBarRender={() => [
      <Button hidden={(documentItems_ReadOnly ?? false)} type="primary" onClick={addNewRow}>
        {t('Add Item')}
      </Button>,
    ]}
    key={refreshKey}
    //dataSource={dataSource}
    // dataSource={[...dataSource]}
    value={dataSource}
    editableFormRef={editableFormRef}
    rowKey="id"
    headerTitle={t('Items')}
    // maxLength={5}
    scroll={{
      y: 200,
    }}
    columns={columns}
    onChange={handleTableChange}
    recordCreatorProps={false}
    /*recordCreatorProps={
      {
        position: 'bottom', newRecordType: 'dataSource',
        record: () => {
          // const newRow : DocumentItemDto = { id: (Math.random() * 1000000).toFixed(0).toString(),item_Id:null, unitPrice: 0, quantity: 1, totalPrice: 0 };
          const newRow: DocumentItemDto = { id: 'bda3d7c4-97ff-4f9b-b56e-f9e6fb4b666f', item_Id: null, unitPrice: 0, quantity: 1, totalPrice: 0 };
          //setDataSource(prevState => [...prevState, newRow]);
          return newRow;
        },
        // record: () => ({ id: randomUUID() }),
        creatorButtonText: t('Add Item'),
      }
    }*/
    editable={{
      type: 'multiple',
      editableKeys,
      // formProps:{},
      onChange: setEditableRowKeys,
      cancelText: t('Cancel'),
      saveText: t('Save'),
      deleteText: t('Delete'),
    }}
    // editable={{
    //   type: 'multiple',
    //   editableKeys,
    //   onSave: async (rowKey, data, row) => {
    //     console.log(rowKey, data, row);
    //   },
    //   onChange: setEditableRowKeys,
    // }}
    size="small"
    summary={(currentData) => {
      console.log('===> data: ', currentData);
      // const totalSum = currentData.reduce((acc, record) => acc + ((record.totalPrice ?? 0)), 0);
      var totalSum = 0;
      var totalTax = 0;
      currentData.forEach(({ quantity, unitPrice, taxRatePerUnit }) => {
        totalTax += calc_totalTaxAmount(quantity ?? 0, unitPrice ?? 0, taxRatePerUnit ?? 0);
        totalSum += calc_totaAmount(quantity ?? 0, unitPrice ?? 0, taxRatePerUnit ?? 0);
      });
      return (
        <Table.Summary fixed={'bottom'}>
          <Table.Summary.Row>
            <Table.Summary.Cell index={0} colSpan={5}>
              <strong> {t('Total Sum')}: </strong>
            </Table.Summary.Cell>
            {/* <Table.Summary.Cell index={5}><strong>{parseFloat((totalTax).toFixed(2))}</strong></Table.Summary.Cell> */}
            <Table.Summary.Cell index={6}><strong>{parseFloat((totalSum).toFixed(2))}</strong></Table.Summary.Cell>
          </Table.Summary.Row>
        </Table.Summary>
      );
    }}
  />
  );
};

export default DocumentsFormItems;
